.header-section {
  .appBar {
    z-index: 1201;
  }
  .toolbar {
    padding-top: 72px;
  }
  .menuButton {
    @media only screen and (min-width: 601px) {
      display: none;
    }
  }
}

.Mui-selected {
  .MuiSvgIcon-root {
    color: #0085ad !important;
  }
  .MuiListItemText-root {
    color: #0085ad !important;
  }
}

.evlg-button-block {
  .w3-col {
    padding: 8px;
  }
}
#root {
  height: 100vh;
}
.evlg-margin-auto {
  margin: auto;
}
.main-content {
  margin-top: 64px;
  border-bottom: solid 100px #f1f1f1;
  background: #f1f1f1;
  @media only screen and (min-width: 601px) {
    margin-left: 240px;
  }
  min-height: 100vh;
}

.error-text {
  font-size: 14px;
  color: red;
}

.textMarginCenter {
  text-align: center;
  padding-top: 5px;
}

.notFoundMsg {
  font-size: 20px;
}

.marginAuto {
  margin: auto !important;
}

.marginTop50 {
  margin-top: 50px !important;
}

.rightAlign {
  text-align: right;
}

.alignCenter {
  text-align: center;
}

.padding16 {
  padding: 16px !important;
}

.createBtn {
  position: absolute !important;
  right: 110px;
  top: 15px;
  z-index: 1202;
}

.responsive-table {
  width: 100%;
  overflow-x: auto;
}

.app-auth-form {
  .MuiCard-root {
    background: #e9ebed;
    input {
      background: white;
    }
  }
}

img {
  page-break-before: avoid; /* 'always,' 'avoid,' 'left,' 'inherit,' or 'right' */
  page-break-after: avoid; /* 'always,' 'avoid,' 'left,' 'inherit,' or 'right' */
  page-break-inside: avoid; /* or 'auto' */
}

.card-padding {
  padding: 15px 30px;
}
body {
  background:  url('https://secureservercdn.net/160.153.138.178/qvt.477.myftpupload.com/wp-content/uploads/2022/06/home_slider.webp');
  background-size: cover;
}

.app-footer {
  width: 100%;
  background: rgba(255,255, 255, 0.96) !important;
  color: #7A7E9E;
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  background-repeat: no-repeat;
  position: fixed;
  left: 0;
  bottom: 0;
  background-color: red;
  text-align: center;
  min-height: 50px;
  z-index: 10000;
  img {
    vertical-align: middle;
    position: absolute;
    right: 0px;
    height: 80px;
    bottom: -1px;
  }
  .copy-right {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 10px;
    // color: #000;
  }
}

.appBar {
  background-color: rgba(255,255,255,0.96) !important;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1) !important;
}

.vertical-align {
  display: flex;
  align-items: center;
  justify-content: center;
}

.add-item-card {
  background: #ffffff;
  border: dashed 1px #333;
  cursor: pointer;
  min-height: 120px;
  line-height: 120px;
}
.stats-cards {
  .stats-card-item {
    padding: 10px;
    margin: 16px;
  }
}

@media only screen and (max-width: 601px) {
  .createBtn {
    position: relative !important;
    right: 20px;
    top: 0px;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.description-text {
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 5px 0px;
}

.wrap-text {
  white-space: pre-wrap;
}

.no-wrap-text {
  white-space: nowrap;
}

.non-clickable {
  cursor: not-allowed;
}

.pagination {
  margin: auto;
  .current-page {
    color: white;
    background-color: #3f51b5;
  }
  .page-no {
    color: black;
    background-color: white;
  }
}

.evlg-doughnut-center-text {
  font-weight: 600;
  font-size: 18px;
  color: rgba(0, 0, 0, 0.87);
}

@media print {
  .header-section {
    display: none;
  }

  body,
  .main-content {
    margin: 0px;
    padding: 0px;
    background: #ffffff !important;
    border: none;
    -webkit-print-color-adjust: exact !important;
  }
  .app-footer {
    display: none;
  }
}

.odr-ext-layout {
  min-height: 100vh;
  body {
    background-color: #e5e5e5;
  }

  body.h7t-modal-open {
    overflow: hidden;
  }

  .margin-50 {
    margin: 50px !important;
  }

  .ticket-margin {
    margin: 60px 100px 60px 100px;
  }

  .MuiAppBar-colorPrimary {
    background-color: white !important;
  }
  th {
    font-weight: bold !important;
    white-space: nowrap;
  }

  table tr:nth-child(2n) td {
    background-color: #eee;
  }

  .h7t-sidebar-section {
    position: fixed !important;
    height: 100%;
    overflow: auto;
    padding-top: 60px;
  }

  .odr-sticky-header {
    min-height: 50px;
    background: #fff !important;
    color: #fff;
  }

  .font-bold {
    font-weight: bold !important;
  }

  .app-bg {
    position: relative;
    background-size: cover;
    background-color: #fff;
  }

  .content-margin {
    padding: 16px;
  }

  .margin-20 {
    margin-bottom: 20px !important;
  }

  .paddingRight {
    padding-right: 0px !important;
  }

  .attachment-padding {
    padding-left: 16px;
    padding-right: 16px;
    position: relative;
  }

  @media only screen and (max-width: 601px) {
    .feedback-height {
      height: 100%;
    }
    .initiate-styles {
      position: absolute;
      bottom: 0px;
      right: 0px;
    }
    .app-bg {
      padding-bottom: 30px;
    }
    .paddingRight {
      padding-right: 16px !important;
    }
  }

  .odr-font-weight {
    font-weight: 500;
  }

  .button-styles {
    background: #173d58 !important;
    color: white !important;
    text-transform: capitalize !important;
  }

  .odr-white-bg {
    background-color: #ffffff !important;
  }

  .odr-font-size-14 {
    font-size: 14px !important;
  }

  .odr-font-size-12 {
    font-size: 12px !important;
  }

  div.ql-toolbar.ql-snow {
    background-color: white !important;
  }

  div.ql-container.ql-snow {
    background-color: white !important;
  }

  .confirmationStyle {
    margin-top: -13px !important;
    margin-bottom: 16px;
  }

  .topnav {
    overflow: hidden;
    background-color: #333;
  }

  .topnav a {
    float: left;
    display: block;
    color: #f2f2f2;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
    font-size: 17px;
  }

  .topnav a:hover {
    background-color: #ddd;
    color: black;
  }

  .topnav a.active {
    background-color: #4caf50;
    color: white;
  }

  .topnav .icon {
    display: none;
  }

  .homepage-label {
    font-size: xx-large;
    margin-bottom: 60px;
    margin-top: 20px;
    text-align: -webkit-center;
  }

  .app-header-bg {
    background-color: #173d58;
    color: #fff;
  }

  @media screen and (max-width: 600px) {
    .topnav a:not(:first-child) {
      display: none;
    }
    .topnav a.icon {
      float: right;
      display: block;
    }
  }

  @media screen and (max-width: 600px) {
    .topnav.responsive {
      position: relative;
    }
    .topnav.responsive .icon {
      position: absolute;
      right: 0;
      top: 0;
    }
    .topnav.responsive a {
      float: none;
      display: block;
      text-align: left;
    }
  }

  @media only screen and (max-width: 601px) {
    .rating {
      padding: 10px 10px 10px 10px;
    }
  }

  @media only screen and (min-width: 411px) and (max-width: 601px) {
    .rating {
      padding: 10px 38px 10px 38px;
    }
  }

  .rating-size {
    font-size: 3.875rem;
  }
  @media only screen and (min-width: 900px) {
    .rating-size {
      font-size: 4.5rem !important;
    }
  }

  @media only screen and (min-width: 600px) {
    .rating-size {
      font-size: 4.5rem !important;
    }
  }

  @media only screen and (max-width: 601px) {
    .rating-size {
      font-size: 3.5rem !important;
    }
  }
  .MuiRating-root .MuiRating-label {
    flex: 1 !important;
  }
}

.init-card {
  margin: 8% 10% 8% 10%;
  border-radius: 10px !important;
}

.app-landing-page { 
  overflow: auto;
  // background-image: url(https://www.sanjosepatentattorney.com/images/HomeBanners/Attorney-slide1.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: repeat;
  min-height: 90vh;
}

.prior-padding {
  padding-left: 16px;
  padding-right: 16px;
}

.padding-top-25 {
  padding-top: 25px !important;
}

.capitalizeText {
  text-transform: capitalize !important;
}

.padding-left-32 {
  padding-left: 32px !important;
}

.width-25 {
  width: 25%;
}

.width-60 {
  width: 60%;
}

.margin-bottom-25 {
  margin-bottom: 25px !important;
}

.margin-bottom-10{
  margin-bottom: 10px ;
}

.font-weight-600 {
  font-weight: 600;
}

.font-weight-900 {
  font-weight: 900 !important;
}

.dashboard-color {
  color: #0051b0 !important;
  font-size: 12px !important;
}

.customer-name {
  color: #333333;
  font-weight: 600 !important;
  font-size: 18px !important;
  line-height: 32px;
}

.button-color {
  background: #173d58 !important;
  border-radius: 3px;
  color: white !important;
}

.complaint-name {
  font-weight: normal;
  font-size: 26px;
  line-height: 44px;
}
.query {
  color: #0051b0;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
}

.overview {
  font-weight: bold !important;
  line-height: 24px;
  color: #173b57 !important;
}

.ticket-info-card {
  width:100%;
  padding:10px;
  margin-bottom: 16px;
}

.indicator-color {
  background: #173b57;
}
.text-margin-bottom{
  margin-bottom: 8px;
}
.status-styles {
  text-transform: uppercase;
  color: #656565;
  background: white !important;
  font-size: 10px !important;
  padding: 2px 4px;
  border-radius: 2px;
  .dot {
    height: 8px;
    width: 8px;
    border-radius: 50%;
    display: inline-block;
    margin: 0px 2px;
  }
  .active,.open {
    background: rgb(196, 196, 29);
  }
  .closed {
    background: rgb(108, 179, 3);
  }
}

.flex-style {
  display: flex;
}

.odr-profile-pic {
  position: relative;
  display: inline-block;
  width: 100px;
  // height: 100px;
}

.odr-company-logo {
  width: 100px;
  height: 100px;
  object-fit: contain;
  border: 1px solid #e5e6e8;
  border-radius: 4px;
}

.double-arrow {
  p.lesser:before {
    content: "<<";
    display: inline-block;
    margin-right: 5px;
    font-size: 10px;
  }
}

.marginTop100 {
  margin-top: 100px;
}

.odr-hyperlink-color {
  color: #40a9ff !important;
  cursor: pointer;
}

.odr-hyperlink-color:hover {
  color: #000 !important;
}

.fontSize60 {
  font-size: 60px !important;
}

.scroll {
  max-height: 235px;
  overflow-y: scroll;
  box-shadow: inset 0px 0px 7px 2px #ddd;
}

.timeline-container .MuiTimelineItem-missingOppositeContent:before{
  flex: none;
}

.wrapText {
 white-space: pre-wrap;
}

.filter-container {
  padding: 20px;
  min-width: 360px;
  width: 360px;
  padding: 50px 20px;
}

.h3-styles-replace {
    font-size: 24px;
    margin: 10px 0px;
    font-weight: 400;
}

.message-container {
  .main-container {
    min-width:400px;
    
  }
}

.ja-date-time-picker {
  width: 100%;
  label[data-shrink=false] {
    line-height: 0;
  }
  input {
    padding: 12px;
  }
}

.file-close {
  position: absolute;
  top: 15px;
  right: 20px;
}
.gmail-bg {
  background: rgba(242,245,245,0.8);
}

.code-align {
  margin-top: 5% !important;
  margin-bottom: 5% !important;
  justify-content: center;
  max-width: 63.666667% !important;
}

.init-hero-img{
  object-fit: contain;
  max-width: 100%;
  width: 50%;
  margin: 0 40px;
}
.init-hero-res-img{
  object-fit: contain;
  max-width: 100%;
  width: 70%;
  margin: 0 40px;
}
.init-hero-img-wrapper{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.code-img {
  height: 47vh;
  position: relative;
}

.resolution-hero-img{
  width: 65%;
  object-fit: contain;
}

.customer-logo {
  height: 50px;
  margin-top: 2%;
}

.margin-top-2 {
  display: flex;
  margin-top: 2%;
}

.init-card-content {
  font-family: 'DM Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  margin: 0px;
}

.init-card-content-desc {
  font-family: 'DM Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #1A1B24;
}
.fair-common-margin-top {
  margin-top: -10px;
}

.init-issue-details {
  color: #7A7E9E;
  font-family: 'DM Sans', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 23px;
}

.init-issue-desc {
  font-family: 'DM Sans',sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 5px;
  color: #4B4E67;
}

.init-order-info {
  font-family: 'DM Sans',sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #4B4E67;
  margin-top: 20px;
}

.init-order-details {
  font-family: 'DM Sans',sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  margin: 2px;
  padding-bottom: 8px;
}

.color-grey {
  color: #7A7E9E;
}

.color-dark {
  color: #1A1B24;
}

.init-primary-fonts{
  font-family: 'DM Sans',sans-serif !important;
  font-size: 16px !important;
}
.init-exp {
  font-family: 'DM Sans',sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 15px;
  color: #4B4E67;
  padding-right: 60px;  
}

.init-emoji-display{
  font-family: 'DM Sans',sans-serif;
  margin-right: 80px;
  font-weight: 600;
  font-size: 16px;
}

.init-modal-checkbox{
  accent-color: black;
  margin-right: 6px;
  height: 15px;
  width: 15px;
}

.init-thank-modal-ok-btn{
  width: 50%;
  text-transform: unset !important;
  font-weight: 600 !important;
  border-radius: 6px !important;
  border: 1px solid rgb(26 27 36) !important
}
.upload-file-align {
  display: flex !important;
  justify-content: space-between !important;
  
}
.uploaded-file{
  display: flex !important;
  justify-content: space-between !important;
  border: 1px solid !important;
  border-radius: 5px !important;
  padding: 5px 20px !important;
  width: 65% !important;
  font-family: 'DM Sans',sans-serif !important;
}

@media only screen and (max-width: 1300px) {
  .uploaded-file{
    width: 75% !important;
  }
  .init-primary-fonts{
    font-size: 14px !important;
  }
  }


.textarea-style {
  color: #313131;
  font-family: 'DM Sans',sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  width: 90%;
  border-radius: 6px;
  padding: 10px;
  font-style: italic;
  resize: none;
}

.init-primary-btn{
  // font-size: 16px !important;
  text-transform: unset !important;
  font-weight: 600 !important;
  font-family: 'DM Sans' !important;
  border-radius: 6px !important;
}
.init-secondary-btn{
  // font-size: 16px !important;
  text-transform: unset !important;
  font-weight: 600 !important;
  font-family: 'DM Sans' !important;
  border-radius: 6px !important;
  background-color: #FFFFFF !important;
}

.init-primary-btn:hover{
  background: #343547 !important;
}

.init-secondary-btn:hover{
  background: #F3F5F7 !important;
}
.MuiPaper-rounded{
  border-radius: 8px !important;
}
.file-upload-cancel {
  align-self: center !important;
  cursor: pointer !important;
  width: 15px !important;
  height: 15px !important;
  border: 1px solid ;
}

.init-file-upload {
    width: 100%;
    border: 1px solid #1A1B24;
    border-radius: 6px;
    font-family: 'DM Sans';
    text-align: center;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    padding: 7px;
    color: #1A1B24;
    cursor: pointer;
    background: white;
}

.init-file-upload-btn {
  margin-left: 5% !important;
  border: 1px solid #76767C !important;
  box-sizing: border-box !important;
  border-radius: 6px !important;
  padding: 1% 4% !important;
}

.init-submit {
  font-family: 'DM Sans',sans-serif !important;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    background: #1A1B24 !important;
    color: #E9E5E5 !important;
    justify-content: center;
    align-items: center;
    width: 90% !important;
    margin-top: 5% !important;
    margin-bottom: 5% !important;
    padding: 2% !important;
    border-radius: 6px !important;
}

.none-of-submit{
    font-family: 'DM Sans',sans-serif !important;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    background: #1A1B24 !important;
    color: #E9E5E5 !important;
    justify-content: center;
    align-items: center;
    width: 100% !important;
    margin-top: 5% !important;
    margin-bottom: 5% !important;
    padding: 2% !important;
}

.init-submit:hover {
  background: #343547 !important;
  color:#FFFFFF !important;
}
.fs-sad-yes-btn{
  background-color: #000 !important;
  color: white !important;
  min-width: 200px !important;
  box-shadow: unset !important;
}
.file-choose-btn {
  display: none !important;
}

.MuiCheckbox-colorPrimary.Mui-checked {
  color: #1A1B24 !important;
}

.resolution-items {
    width: 100%;
    margin-bottom: 5%;
    margin-top: 2%;
}

.resolution-btn {
  font-family: 'DM Sans',sans-serif !important;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 12px !important;
  line-height: 16px !important;
  background: #FFFFFF !important;
  border: 1px solid #1A1B24 !important;
  box-sizing: border-box !important;
  border-radius: 6px !important;
  padding: 5% 10% !important;
}



.resolution-submit-btn {
  font-family: 'DM Sans',sans-serif !important;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 12px !important;
  line-height: 16px !important;
  background: #1A1B24 !important;
  color: #FFFFFF !important;
  border: 1px solid #1A1B24 !important;
  box-sizing: border-box !important;
  border-radius: 6px !important;
  // padding: 5% 20% !important;
}

.text-align-end {
  text-align: end !important;
}

.MuiButton-root {
  text-transform: none !important;
}

.visable-hidden {
  visibility: hidden;
}

.show-resolution {
  display: flex !important;
}

.hide-resolution {
  display: none !important;
}

.checkbox-name .MuiTypography-body1{
  font-size: 14px !important;
  font-family: 'DM Sans',sans-serif !important;
  color: #7A7E9E !important;
  font-weight: 500 !important;
}

.h7t-content-btn {
  display: flex;
  justify-content: end;
}

.h7t-content-submit-btn {
    font-family: "DM Sans", sans-serif !important;
    font-style: normal;
    font-weight: 500 !important;
    font-size: 12px !important;
    line-height: 16px !important;
    background: #FFFFFF !important;
    border: 1px solid #1A1B24 !important;
    box-sizing: border-box !important;
    border-radius: 6px !important;
}

.h7t-modal-content {
  margin: 3% 0% !important;
}

.h7t-modal-btn {
  margin-top: 4% !important;
}

.h7t-ai-modal {
  position: absolute;
  max-width: 450px;
  top: 18%;
  left: 38%;
  border-radius: 6px !important;
  padding: 5px 10px;
  text-align: -webkit-center !important;
}

#simple-modal-description {
  text-align: center !important;
    padding: 3% !important;
    font-family: 'DM Sans',sans-serif;
    font-style: normal;
    font-weight: 400;
    color: #1A1B24;
}

.simple-modal-content {
  text-align: center !important;
    padding: 3% !important;
    font-family: 'DM Sans',sans-serif;
    font-style: normal;
    font-weight: 500;
    color: #989AB4;
}

.h7t-modal-content-btn {
  font-family: "DM Sans", sans-serif !important;
    font-style: normal;
    font-weight: 600 !important;
    font-size: 12px !important;
    line-height: 16px !important;
    background: #FFFFFF !important;
    border: 1px solid #1A1B24 !important;
    box-sizing: border-box !important;
    border-radius: 6px !important;
    padding: 2% 10% !important;
    margin-bottom: 8% !important;
    display: block;
    width: 55%;
}

.MuiRating-sizeLarge{
  font-size: 4.1rem !important;
}
.MuiPaper-outlined {
  border: 1px solid #4B4E67 !important;
}

.c-logo-wrapper{
  width: 50%;
  position: relative;
}

.modal-c-logo{
  width: 50%;
  position: absolute;
  top: 0;
  left: 10px;
}
.modal-fair-logo{
  width: 70%;
  position: absolute;
  top: 10px;
  right: 5px;
}
.resolution-buttons-wrapper{
  display: flex;
  flex-direction: column;
}
.resolution-buttons{
  margin-bottom: 10px !important;
  font-size: 16px !important;
  font-family: "DM Sans", sans-serif !important;
}

@media only screen and (max-width: 1300px) {
  .resolution-buttons{
    font-size: 12px !important;
  }
  .h7t-ai-modal {
    max-width: 400px;
  }
  }

.sad-emoji-lottie{
  width: 15%;
  margin: auto;
}

.rating-screen-modal-wrap{
  overflow-y: unset !important;
}


@media only screen and (max-width: 1300px) {
  .MuiDialog-paperScrollPaper{
    max-height: unset !important;
  }
  .c-modal-width{
    max-width: 400px !important;
    margin: auto;
  }
}
.resolution-buttons:hover{
  background: #F3F5F7 !important;
}
.c-modal-width{
  max-width: 450px;
  margin: auto;
}
.modal-close-icon{
  cursor: pointer;
  float: right;
  margin: 5px;
  width: 20px;
  &:hover{
    background: #F3F5F7;
  }
}
.init-right-content-wrapper{
  padding:0px 40px 0px 0px !important;
}
.error-validate{
  color: #A53328;
  font-family: 'DM Sans', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  margin-top: -10px;
  display: flex;
  align-items: center;
  .margin-right{
    margin-right: 6px;
  }
}

// Toastify CSS

.Toastify__toast-theme--light {
  color:#FFFFFF !important;
  background-color: #7a7e9eca !important;
}

.Toastify__close-button > svg {
  width: 0px !important;
}

.Toastify__toast-body {
  text-align: center;
}


////
.rating-star-wrapper{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.emoji-caption{
  font-family: 'Staatliches';
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 24px;
  color: #686B7E;
  text-shadow: 3px 3px 0px #DAE7EF;;
}
.resolution-buttons-wrapper{

  .MuiButton-outlined {
    border: 2px solid #1A1B24;
  }
}
