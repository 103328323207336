body {
  margin: 0;
  font-family: -apple-system, /* AvenirNextLTPro-Regular, AvenirNextLTPro-Bold, BlinkMacSystemFont*/
  'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100vh;
}
code {
  font-family: /*AvenirNextLTPro-Regular, AvenirNextLTPro-Bold,*/
          source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
          monospace;
}
